import React, { Component } from 'react';
import {
    Row,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';
import { connect } from 'react-redux';
import { serverResponse } from '../../util/fakeServer';
import ReactGA from 'react-ga';
import { gaEventLabel } from '../../settings/gaConfig';
import {
    isIOS
} from "react-device-detect";

class ShareFlyer extends Component {

    state = {
        modal: false,
        idParam: 0,
    }

    openModal = () => {
        this.setState({ modal: true });
    }

    closeModal = () => {
        this.setState({ modal: false });
    }

    openReactNativeShare = () => {
        let link = serverResponse.config.shareFlyerURL;
        let title = serverResponse.config.shareFlyerTitle;
        var str = title + '    ' +link;
        var obj = { action: "whatsapp", value: str };
        window.ReactNativeWebView.postMessage(JSON.stringify(obj));
    }

    componentDidMount() {
        var idParam = Math.random().toString(36).substr(2, 16);

        this.setState({
            idParam: idParam
        });

        if (serverResponse.config.ga_active) {
            ReactGA.initialize(serverResponse.config.ga_tracking_id);
        }
    }

    render() {

        let link = serverResponse.config.shareFlyerURL;
        let title = serverResponse.config.shareFlyerTitle;

        let wa1 = 'inline';
        let wa2 = 'none';

        if (window.ReactNativeWebView != undefined && isIOS) {
            wa1 = 'none';
            wa2 = 'inline';
        }

        return (
            <div className="width20">
                <li>
                    <a
                        href="/"
                        className="nav-link main-list"
                        onClick={
                            (e) => {
                                e.preventDefault();
                                this.props.forceDrawerMini();
                                this.openModal();
                            }
                        }
                        style={{ cursor: "pointer" }}>

                        <i className="fas fa-share-square" />
                        <p style={this.props.pStyle}><span>Condividi {serverResponse.config.type === 'leaflet' ? 'Volantino' : serverResponse.config.type === 'menu' ? 'Menù' : 'Catalogo'}</span></p>
                    </a>
                </li>
                <Modal
                    isOpen={this.state.modal}
                    toggle={() => this.closeModal()}
                    centered={true}
                >
                    <ModalHeader toggle={() => this.closeModal()}>Condividi con...</ModalHeader>
                    <ModalBody>
                        <Row className="custom-share-container">

                            <FacebookShareButton
                                url={link}
                                beforeOnClick={() => {
                                    ReactGA.event({
                                        category: serverResponse.config.release_id,
                                        action: gaEventLabel.share_leaflet_facebook
                                    });
                                }}
                            >
                                <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>

                            <TwitterShareButton
                                title={title}
                                url={link}
                                beforeOnClick={() => {
                                    ReactGA.event({
                                        category: serverResponse.config.release_id,
                                        action: gaEventLabel.share_leaflet_twitter
                                    });
                                }}
                            >
                                <TwitterIcon size={32} round={true} />
                            </TwitterShareButton>

                            <TelegramShareButton
                                title={title}
                                url={link}
                                beforeOnClick={() => {
                                    ReactGA.event({
                                        category: serverResponse.config.release_id,
                                        action: gaEventLabel.share_leaflet_telegram
                                    });
                                }}
                            >
                                <TelegramIcon size={32} round={true} />
                            </TelegramShareButton>

                            <WhatsappShareButton
                                style={{display: wa1}}
                                title={title}
                                url={link}
                                beforeOnClick={() => {
                                    ReactGA.event({
                                        category: serverResponse.config.release_id,
                                        action: gaEventLabel.share_leaflet_whatsapp
                                    });
                                }}
                            >
                                <WhatsappIcon size={32} round={true} />
                            </WhatsappShareButton>

                            <span style={{display: wa2}} onClick={() => this.openReactNativeShare()}>
                                <WhatsappIcon size={32} round={true} />
                            </span>

                            {/**subject: Title of the shared page (string)
                                body: Body of the email (string), will be prepended to the url.
                                separator: Separates body from the url, default: " " (string)
                                openWindow: Opens the mail client in a new window. Defaults to false (bool) */}
                            <EmailShareButton
                                url={link}
                                subject={title}
                                openWindow={true}
                                beforeOnClick={() => {
                                    ReactGA.event({
                                        category: serverResponse.config.release_id,
                                        action: gaEventLabel.share_leaflet_mail
                                    });
                                }}
                            >
                                <EmailIcon size={32} round={true} />
                            </EmailShareButton>

                        </Row>
                    </ModalBody>
                </Modal>

            </div>
        );
    }
}

const mapStateToProps = state => {

    return {
        groceryList: state.groceryList,
    };

}

export default connect(mapStateToProps, null)(ShareFlyer);