import { serverResponse } from '../fakeServer';
import { isMobile } from 'react-device-detect';

var sidebarData = [];

if (serverResponse.config.type === 'menu') {
  sidebarData = [
    {
      name: 'Menù',
      routepath: '/leaflet',
      iconClass: 'fas fa-book-open',
      action: (e) => { }
    },
    {
      name: 'sidebar.pages',
      routepath: '/pages',
      iconClass: 'fas fa-columns',
      action: (e) => { }
    },
    {
      name: 'sidebar.pdf',
      routepath: '/Pdf',
      iconClass: 'fas fa-file-pdf',
      action: (e) => {
        e.preventDefault();
        if (window.ReactNativeWebView != undefined) {
          var obj = { action: "download", value: 'https://' + window.location.host + '/' + serverResponse.config.pdfPath };
          window.ReactNativeWebView.postMessage(JSON.stringify(obj));
        } else {
          window.open(process.env.PUBLIC_URL + serverResponse.config.pdfPath, '_blank');
        }
      }
    },
    {
      name: 'sidebar.fullscreen',
      id: 'fullScreenId',
      routepath: '/fullScreen',
      iconClass: 'fas fa-arrows-alt',
      action: (e) => {
        e.preventDefault();
        //var routeHeight =document.getElementById("root").firstChild;
        var routeHeight = window.document.documentElement;
        if (routeHeight.requestFullscreen) {
          routeHeight.requestFullscreen();
        } else if (routeHeight.mozRequestFullScreen) { /* Firefox */
          routeHeight.mozRequestFullScreen();
        } else if (routeHeight.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          routeHeight.webkitRequestFullscreen();
        } else if (routeHeight.msRequestFullscreen) { /* IE/Edge */
          routeHeight.msRequestFullscreen();
        }
      }
    }
  ];
} else if (serverResponse.config.type === 'catalog') {
  sidebarData = [
    {
      name: 'Catalogo',
      routepath: '/leaflet',
      iconClass: 'fas fa-book-open',
      action: (e) => { }
    },
    {
      name: 'sidebar.categories',
      routepath: '/categories',
      iconClass: (isMobile) ? 'fas fa-search' : 'fas fa-list-ul',
      action: (e) => { }
    },
    {
      name: 'sidebar.pages',
      routepath: '/pages',
      iconClass: 'fas fa-columns',
      action: (e) => { }
    },
    {
      name: 'sidebar.pdf',
      routepath: '/Pdf',
      iconClass: 'fas fa-file-pdf',
      action: (e) => {
        e.preventDefault();
        if (window.ReactNativeWebView != undefined) {
          var obj = { action: "download", value: 'https://' + window.location.host + '/' + serverResponse.config.pdfPath };
          window.ReactNativeWebView.postMessage(JSON.stringify(obj));
        } else {
          window.open(process.env.PUBLIC_URL + serverResponse.config.pdfPath, '_blank');
        }
      }
    },
    {
      name: 'sidebar.fullscreen',
      id: 'fullScreenId',
      routepath: '/fullScreen',
      iconClass: 'fas fa-arrows-alt',
      action: (e) => {
        e.preventDefault();
        //var routeHeight =document.getElementById("root").firstChild;
        var routeHeight = window.document.documentElement;
        if (routeHeight.requestFullscreen) {
          routeHeight.requestFullscreen();
        } else if (routeHeight.mozRequestFullScreen) { /* Firefox */
          routeHeight.mozRequestFullScreen();
        } else if (routeHeight.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          routeHeight.webkitRequestFullscreen();
        } else if (routeHeight.msRequestFullscreen) { /* IE/Edge */
          routeHeight.msRequestFullscreen();
        }
      }
    },/*
    {
      name: 'sidebar.chat',
      routepath: '/chat',
      iconClass: 'fas fa-comments',
      action: (e) => { e.preventDefault();
        window.open('https://volantinointerattivo.net')}
    }*/
  ];
}
else if (serverResponse.config.has_pages) {
  sidebarData = [
    {
      name: 'sidebar.leaflet',
      routepath: '/leaflet',
      iconClass: 'fas fa-book-open',
      action: (e) => { }
    },
    {
      name: 'sidebar.categories',
      routepath: '/categories',
      iconClass: (isMobile) ? 'fas fa-search' : 'fas fa-list-ul',
      action: (e) => { }
    },
    {
      name: 'sidebar.pages',
      routepath: '/pages',
      iconClass: 'fas fa-columns',
      action: (e) => { }
    },

    {
      name: 'sidebar.pdf',
      routepath: '/Pdf',
      iconClass: 'fas fa-file-pdf',
      action: (e) => {
        e.preventDefault();
        console.log(window.location.host);
        if (window.ReactNativeWebView != undefined) {
          var obj = { action: "download", value: 'https://' + window.location.host + '/' + serverResponse.config.pdfPath };
          window.ReactNativeWebView.postMessage(JSON.stringify(obj));
        } else {
          window.open(process.env.PUBLIC_URL + serverResponse.config.pdfPath, '_blank');
        }
        //  window.open(process.env.PUBLIC_URL + serverResponse.config.pdfPath, '_blank');
      }
    },
    {
      name: 'sidebar.fullscreen',
      id: 'fullScreenId',
      routepath: '/fullScreen',
      iconClass: 'fas fa-arrows-alt',
      action: (e) => {
        e.preventDefault();
        //var routeHeight =document.getElementById("root").firstChild;
        var routeHeight = window.document.documentElement;
        if (routeHeight.requestFullscreen) {
          routeHeight.requestFullscreen();
        } else if (routeHeight.mozRequestFullScreen) { /* Firefox */
          routeHeight.mozRequestFullScreen();
        } else if (routeHeight.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          routeHeight.webkitRequestFullscreen();
        } else if (routeHeight.msRequestFullscreen) { /* IE/Edge */
          routeHeight.msRequestFullscreen();
        }
      }
    }
  ];
}
else {
  sidebarData = [
    {
      name: 'sidebar.categories',
      routepath: '/categories',
      iconClass: (isMobile) ? 'fas fa-search' : 'fas fa-list-ul',
      action: (e) => { }
    },/*
    {
      name: 'sidebar.pdf',
      routepath: '/Pdf',
      iconClass: 'fas fa-file-pdf',
      action: (e) => {
        e.preventDefault();
        window.open(process.env.PUBLIC_URL + serverResponse.config.pdfPath, '_blank');
      }
    },*/
    {
      name: 'sidebar.fullscreen',
      id: 'fullScreenId',
      routepath: '/fullScreen',
      iconClass: 'fas fa-arrows-alt',
      action: (e) => {
        e.preventDefault();
        //var routeHeight =document.getElementById("root").firstChild;
        var routeHeight = window.document.documentElement;
        if (routeHeight.requestFullscreen) {
          routeHeight.requestFullscreen();
        } else if (routeHeight.mozRequestFullScreen) { /* Firefox */
          routeHeight.mozRequestFullScreen();
        } else if (routeHeight.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          routeHeight.webkitRequestFullscreen();
        } else if (routeHeight.msRequestFullscreen) { /* IE/Edge */
          routeHeight.msRequestFullscreen();
        }
      }
    }
  ];
}

export { sidebarData };