const leaflets = {
  "iperbimbo": {
    "config": {
      "primary-color": "#5ac5f3",
      "secondary-color": "#e30713",
      "tertiary-color": "#ffdd00",
      "hover-color": "rgba(220, 35, 17, 0.5)",
      "clientIcon": require("assets/images/clientLogo_iperbimbo.png"),
      "serverPath": "demo2.catalogointerattivo.net/",
      "pdfPath": "/media/pdf/catalogo.pdf",
      "shareFlyerTitle": "Dai uno sguardo al Catalogo di Interattivo di Iperbimbo!",
      "shareFlyerURL": "https://demo2.catalogointerattivo.net?v=172",
      "shareProductSentence": "Guarda questa fantastica offerta!",
      "ogTitleMeta": "Iperbimbo | Catalogo Interattivo",
      "ogDescriptionMeta": "Dai uno sguardo al nuovo catalogo di Iperbimbo.",
      "ogImageMeta": "https://demo2.catalogointerattivo.net/media/images/catalogo_fb.png",
      "filterBrand": 0,
      "filterUnderprice": 0,
      "brandImage": "",
      "underPriceImage": "",
      "ga_active": false,
      "ga_tracking_id": "UA-152069090-10",
      "release_id": "2",
      "send_grocery_list_to_market": false,
      "grocery_list_min_value": -1,
      "client_id": 7,
      "signboard_id": 11,
      "has_pages": true,
      "hide_grocery_list": false,
      "hide_searchbar": false,
      "line_through": false,
      "category_banner": "",
      "category_banner_mobile": "",
      "hide_plus_product": false,
      "hide_plus_product_price": false,
      "type": "leaflet",
      "product_banner": "cstm69543145",
      "href_banner": "",
      "internal_banner_click": false,
      "external_banner_click": false,
      "hide_listPages": false,
      "type": "catalog"
    },
    "leaflet": {
      "name": "Iperbimbo Catalogo giochi",
      "code": "172",
      "id_campaign": "172",
      "index": {
        "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/pages/Indice-Iperbimbocatalogo-giochi_NhTmMLU.png",
        "links": [
          {
            "page": 2,
            "blueprint": {
              "top": 20.15220109196701,
              "left": 12.875891836403145,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 22,
            "blueprint": {
              "top": 24.539309074937133,
              "left": 12.584573421303796,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 30,
            "blueprint": {
              "top": 28.958776398641906,
              "left": 12.449413368507967,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 38,
            "blueprint": {
              "top": 33.20691696796071,
              "left": 12.645417783689055,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 41,
            "blueprint": {
              "top": 37.81141986338286,
              "left": 12.57272259029783,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 48,
            "blueprint": {
              "top": 42.07022284618165,
              "left": 12.526417266590817,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 60,
            "blueprint": {
              "top": 46.56964354759339,
              "left": 12.233485926888099,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 70,
            "blueprint": {
              "top": 50.990639427184945,
              "left": 12.469343967362727,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 78,
            "blueprint": {
              "top": 55.396023156283086,
              "left": 12.416571800410287,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 84,
            "blueprint": {
              "top": 59.82577276223155,
              "left": 12.277648256873267,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 89,
            "blueprint": {
              "top": 64.23191675380227,
              "left": 12.754740753167084,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 97,
            "blueprint": {
              "top": 68.43779583137903,
              "left": 12.645970570055468,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 101,
            "blueprint": {
              "top": 72.66309372577321,
              "left": 12.48657878612923,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 105,
            "blueprint": {
              "top": 77.06314490964108,
              "left": 12.761745237946968,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 109,
            "blueprint": {
              "top": 81.36001990964108,
              "left": 12.71381941722061,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": 112,
            "blueprint": {
              "top": 85.63100850784558,
              "left": 12.678812138153148,
              "width": 74.81793461573352,
              "height": 4.308704526934945
            }
          },
          {
            "page": -1,
            "type": "url",
            "url": "https://catalogointerattivo.net",
            "target": "_blank",
            "blueprint": {
              "top": 4.8905282398442,
              "left": 58.717499331023056,
              "width": 30.0,
              "height": 5.846687155078934
            }
          }
        ]
      },
      "categories": [
        {
          "category_name": "Giochi e Giocattoli",
          "subcategories": [
            "Prima Infanzia"
          ]
        }
      ],
      "pages": [
        {
          "number": 1,
          "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/pages/copertina_VZwC2t5.jpg",
          "products": []
        },
        {
          "number": 2,
          "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/pages/page_2_H1Bp6Wi.jpg",
          "products": [
            {
              "item_id": "cstm17000532",
              "field1": "GIOCO FD NIDO DI COCCOLE ROSA/BLU",
              "field2": "",
              "field3": "",
              "field4": "",
              "description": "GIOCO FD NIDO DI COCCOLE ROSA/BLU",
              "grammage": null,
              "price": "0.00",
              "price_label": "-",
              "equivalence": null,
              "quantity_step": null,
              "grocery_label": "",
              "varieties": [
                "Rosa",
                "Blu"
              ],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Giochi e Giocattoli",
              "subcategory": "Prima Infanzia",
              "blueprint": {
                "top": 14.782014020915527,
                "left": 8.29685289957567,
                "width": 43.80162659123055,
                "height": 26.376566017574902
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/products/cropped_image_p4CqIek_yYAKTCV.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm61091730",
              "field1": "PROIETTORE NEXT2STARS",
              "field2": "",
              "field3": "",
              "field4": "",
              "description": "PROIETTORE NEXT2STARS",
              "grammage": null,
              "price": "0.00",
              "price_label": "-",
              "equivalence": null,
              "quantity_step": null,
              "grocery_label": "",
              "varieties": [
                "Rosa",
                "Blu"
              ],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Giochi e Giocattoli",
              "subcategory": "Prima Infanzia",
              "blueprint": {
                "top": 14.923456736615668,
                "left": 51.9978783592645,
                "width": 43.80162659123055,
                "height": 24.042761208522567
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/products/cropped_image_hJVXaSC_87YIqfF.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm77416706",
              "field1": "GIOSTRINA NEXT2DREAMS",
              "field2": "",
              "field3": "",
              "field4": "",
              "description": "GIOSTRINA NEXT2DREAMS",
              "grammage": null,
              "price": "0.00",
              "price_label": "-",
              "equivalence": null,
              "quantity_step": null,
              "grocery_label": "",
              "varieties": [
                "Rosa",
                "Azzurro",
                "Neutra"
              ],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Giochi e Giocattoli",
              "subcategory": "Prima Infanzia",
              "blueprint": {
                "top": 42.43406494029318,
                "left": 6.092468175388959,
                "width": 43.80162659123055,
                "height": 27.08377959607561
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/products/cropped_image_dDuldSa_DFviP7h.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm32050111",
              "field1": "GIOSTRINA PROIETTORE NEXT2MOON",
              "field2": "",
              "field3": "",
              "field4": "",
              "description": "GIOSTRINA PROIETTORE NEXT2MOON",
              "grammage": null,
              "price": "0.00",
              "price_label": "-",
              "equivalence": null,
              "quantity_step": null,
              "grocery_label": "",
              "varieties": [
                "Rosa",
                "Azzurra",
                "Neutra"
              ],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Giochi e Giocattoli",
              "subcategory": "Prima Infanzia",
              "blueprint": {
                "top": 39.46376791059021,
                "left": 48.99752475247524,
                "width": 43.80162659123055,
                "height": 27.08377959607561
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/products/cropped_image_P8Z9zrG_weUAp1D.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm96804896",
              "field1": "PUPAZZO PROIETTORE BABY BEAR",
              "field2": "",
              "field3": "",
              "field4": "",
              "description": "PUPAZZO PROIETTORE BABY BEAR",
              "grammage": null,
              "price": "0.00",
              "price_label": "-",
              "equivalence": null,
              "quantity_step": null,
              "grocery_label": "",
              "varieties": [
                "Rosa",
                "Azzurro"
              ],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Giochi e Giocattoli",
              "subcategory": "Prima Infanzia",
              "blueprint": {
                "top": 70.01539450182077,
                "left": 9.992927864214984,
                "width": 39.00106082036775,
                "height": 24.820696144873345
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/products/cropped_image_ZsUiCOd_ivP9Yl3.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm21658403",
              "field1": "PALESTRINA DEI COLORI",
              "field2": "",
              "field3": "",
              "field4": "",
              "description": "PALESTRINA DEI COLORI",
              "grammage": null,
              "price": "0.00",
              "price_label": "-",
              "equivalence": null,
              "quantity_step": null,
              "grocery_label": "",
              "varieties": [
                "Rosa",
                "Azzurro"
              ],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Giochi e Giocattoli",
              "subcategory": "Prima Infanzia",
              "blueprint": {
                "top": 64.99417809446574,
                "left": 48.797501178689295,
                "width": 41.701379066478076,
                "height": 29.559027120828084
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/products/cropped_image_wZeqcoK_XLbhcMa.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            }
          ]
        },
        {
          "number": 3,
          "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/pages/page_3_iGPpp32.jpg",
          "products": [
            {
              "item_id": "cstm88420522",
              "field1": "GIOSTRINA CASINA DELLE API",
              "field2": "",
              "field3": "",
              "field4": "",
              "description": "GIOSTRINA CASINA DELLE API",
              "grammage": null,
              "price": "0.00",
              "price_label": "-",
              "equivalence": null,
              "quantity_step": null,
              "grocery_label": "",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Giochi e Giocattoli",
              "subcategory": "Prima Infanzia",
              "blueprint": {
                "top": 12.02388106476276,
                "left": 4.092232437529464,
                "width": 41.701379066478076,
                "height": 29.559027120828084
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/products/cropped_image_nVzVWKe_KaACbxf.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm29870071",
              "field1": "PALESTRINA BUBBLE GYM",
              "field2": "",
              "field3": "",
              "field4": "",
              "description": "PALESTRINA BUBBLE GYM",
              "grammage": null,
              "price": "0.00",
              "price_label": "-",
              "equivalence": null,
              "quantity_step": null,
              "grocery_label": "",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Giochi e Giocattoli",
              "subcategory": "Prima Infanzia",
              "blueprint": {
                "top": 13.438308221764176,
                "left": 47.797383309759546,
                "width": 41.701379066478076,
                "height": 24.396367997772924
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/products/cropped_image_XGNF2tW_3s87Uv4.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm56011675",
              "field1": "TAPPETO CON ARCHI FORESTA MAGICA",
              "field2": "",
              "field3": "",
              "field4": "",
              "description": "TAPPETO CON ARCHI FORESTA MAGICA",
              "grammage": null,
              "price": "0.00",
              "price_label": "-",
              "equivalence": null,
              "quantity_step": null,
              "grocery_label": "",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Giochi e Giocattoli",
              "subcategory": "Prima Infanzia",
              "blueprint": {
                "top": 41.1610804989919,
                "left": 7.292609618104664,
                "width": 44.4016973125884,
                "height": 24.396367997772924
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/products/cropped_image_9yGPaqs_ShtliGQ.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm91175685",
              "field1": "TEDDY ORSO DELLE EMOZIONI",
              "field2": "",
              "field3": "",
              "field4": "",
              "description": "TEDDY ORSO DELLE EMOZIONI",
              "grammage": null,
              "price": "0.00",
              "price_label": "-",
              "equivalence": null,
              "quantity_step": null,
              "grocery_label": "",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Giochi e Giocattoli",
              "subcategory": "Prima Infanzia",
              "blueprint": {
                "top": 37.90789803788864,
                "left": 48.59747760490335,
                "width": 40.60124941065535,
                "height": 26.8716155225254
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/products/cropped_image_wbCoXEe_i5ICARX.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm56336273",
              "field1": "LUCKY",
              "field2": "IL CUCCIOLO RACCONTASTORIE",
              "field3": "",
              "field4": "",
              "description": "LUCKY IL CUCCIOLO RACCONTASTORIE",
              "grammage": null,
              "price": "0.00",
              "price_label": "-",
              "equivalence": null,
              "quantity_step": null,
              "grocery_label": "",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Giochi e Giocattoli",
              "subcategory": "Prima Infanzia",
              "blueprint": {
                "top": 67.54014697706829,
                "left": 7.892680339462516,
                "width": 40.60124941065535,
                "height": 27.508107743176033
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/products/cropped_image_vujRS26_eGHenCM.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            },
            {
              "item_id": "cstm72980777",
              "field1": "PALESTRA CRESCI E CAMMINA",
              "field2": "",
              "field3": "",
              "field4": "",
              "description": "PALESTRA CRESCI E CAMMINA",
              "grammage": null,
              "price": "0.00",
              "price_label": "-",
              "equivalence": null,
              "quantity_step": null,
              "grocery_label": "",
              "varieties": [],
              "price_for_kg": null,
              "available_pieces": null,
              "max_purchasable_pieces": null,
              "points": "",
              "fidelity_product": false,
              "focus": false,
              "pam": false,
              "three_for_two": false,
              "one_and_one_gratis": false,
              "underpriced_product": false,
              "category": "Giochi e Giocattoli",
              "subcategory": "Prima Infanzia",
              "blueprint": {
                "top": 65.48922759941624,
                "left": 47.497347949080634,
                "width": 40.60124941065535,
                "height": 27.508107743176033
              },
              "images": [
                {
                  "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/products/cropped_image_Vhb7K2j_qeQlb3Z.png",
                  "cropped": true
                }
              ],
              "markers": [
                {
                  "type": "plus",
                  "title": null,
                  "data": ""
                }
              ]
            }
          ]
        },
        {
          "number": 114,
          "image_file": "https://interattivo.s3.amazonaws.com/g4nrsoFuJ0/pages/fine-Iperbimbo_AIjEmc9.png",
          "products": []
        }
      ]
    }
  }

}

const lefletSwitch = () => {
  return leaflets.iperbimbo;
}

export const serverResponse = lefletSwitch(); 